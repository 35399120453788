import React from "react"
import { Helmet } from "react-helmet"
import Header from "../components/layout/header"
import favicon from "../images/favicon.ico"

import { Link } from "gatsby"
import pageNotFound from "../images/page-not-found.svg"

export default () => (
  <div>
    <Helmet>
      <meta charSet="utf-8"></meta>
      <title>
        EzriRx.com - Pharmacy Marketplace - Pharmacy Platform - 65,000+ Rx
        Pharmacy Items
      </title>
      <meta name="keywords" content="ezrirx, pharmacy purchasing platform" />
      <meta
        name="description"
        content="EzriRx's pharmacy marketplace allows pharmacies to compare drug prices offered by many suppliers and purchase from the most favorable. Browse, compare, and save an average of 30% on all drugs."
      />
      <link rel="shortcut icon" type="image/png" href={`${favicon}`} />
    </Helmet>
    <div id="container">
      <Header headerBg />
      <div className="body">
        <section className="page-not-found">
          <div className="contents">
            <div className="contact-wrap">
              <div className="row">
                <div className="col-xs-12">
                  <img src={pageNotFound} alt="Page not found" />
                  <div className="contents">
                    <div className="title-box">
                      <h2>Oops! We couldn't find the page you're looking for</h2>
                      <p>
                        It seems we can't find any results based on your search.
                        Please choose one of the link below to continue
                      </p>
                      <Link to="/" className="button blue-md">
                        Back to homepage
                      </Link>

                      <div className="split-button">
                        <a href="/contact/">Contact Us</a> 
                        <a href="https://app.ezrirx.com/login/">Sign In</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
)
